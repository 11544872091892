<template>
  <div v-if="$can('create', 'expense')">
    <b-tabs
      active-nav-item-class="font-weight-bold text-uppercase text-primary"
      active-tab-class="font-weight-bold text-success"
      content-class="mt-1"
    >
      <b-tab lazy title="Add Expenses" active>
        <template #title>
          <feather-icon icon="PlusIcon" size="18" />Add Expenses
        </template>
        <b-card border-variant="primary">
          <b-card-text>
            <b-form @submit.prevent>
              <b-row>
                <b-col md="3">
                  <b-form-group label="Expense Date">
                    <flat-pickr
                      v-model="expenseDate"
                      placeholder="Expense Date"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group label="Movie">
                    <v-select
                      @input="getMovieVendors"
                      v-model="selectedMovie"
                      placeholder="Movie"
                      label="full_title"
                      :options="optionMovies"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Licensor">
                    <v-select
                      :disabled="!selectedMovie ? true : false"
                      v-model="selectedCustomer"
                      placeholder="Licensor"
                      label="company"
                      :options="optionCustomer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                :disabled="
                  !selectedCustomer || !selectedMovie || !expenseDate || loading
                    ? true
                    : false
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1 md-mb-0"
                @click="getReportContracts"
                >Fetch Data</b-button
              >
              <b-card
                v-if="showExpenseSection && revenueTypeArray.length"
                border-variant="success"
                bg-variant="light"
                class="mt-1"
              >
                <b-card-text>
                  <b-row class="mt-1">
                    <b-col>
                      <h5 style="color: #00d09c"><b>Expense Type</b></h5>
                    </b-col>
                    <b-col>
                      <h5 style="color: #00d09c"><b>Territory</b></h5>
                    </b-col>
                    <b-col>
                      <h5 style="color: #00d09c"><b>Amount (CHF)</b></h5>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="(item, index) in revenueTypeArray"
                    :key="index"
                    class="mt-1"
                  >
                    <b-col>
                      {{ item.lbl_value1 }}
                      <b-form-input
                        disabled
                        id="from"
                        v-model="item.lbl_name"
                        placeholder="Revenue Type"
                      />
                    </b-col>
                    <b-col>
                      <v-select
                        v-model="item.territory_id"
                        :reduce="(terr) => terr.id"
                        placeholder="Territory"
                        label="ter"
                        :options="optionAvailableTeritory"
                      />
                    </b-col>

                    <b-col>
                      {{ item.lbl_value1 }}
                      <b-form-input
                        id="from"
                        v-model="item.expense_amount"
                        placeholder="Amount"
                        type="number"
                      />
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>

              <b-row
                v-if="showExpenseSection && revenueTypeArray.length"
                class="mt-0"
              >
                <!-- submit and reset -->
                <b-col class="d-flex justify-content-center">
                  <b-button
                    :disabled="
                      !selectedCustomer ||
                      !selectedMovie ||
                      !expenseDate ||
                      loading
                        ? true
                        : false
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1 mb-1 md-mb-0"
                    @click="addExpenseData"
                  >
                    <b-spinner v-if="loading" small /> Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    class="mb-1 md-mb-0"
                    @click="resetForm"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
        </b-card>
      </b-tab>
      <b-tab lazy title="Upload Expense Excel">
        <template #title>
          <feather-icon icon="FileIcon" size="18" />Upload Expense
        </template>
        <b-card border-variant="primary"
          ><b-card-text>
            <div v-if="errorExcelData" class="mb-1">
              <span>Issues Found in uploaded File. </span>
              <span
                >Please click on download to get file with errors
                <b-badge
                  @click="downloadErrorExcel"
                  variant="danger"
                  class="cursor-pointer"
                >
                  Download </b-badge
                ><br />
              </span>
              <span>Upload the downloaded file again after error fixes.</span>
            </div>
            <b-form @submit.prevent>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Expense Excel">
                    <b-form-file
                      v-model="expenseFile"
                      id="IANAd"
                      accept=".xlsx"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-button
                    :disabled="!expenseFile || loading ? true : false"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1 mt-2"
                    @click="UploadExpenseData"
                  >
                    <b-spinner v-if="loading" small /> Upload
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="outline-secondary"
                    class="mt-2"
                    @click="downloadSample"
                  >
                    download sample excel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text></b-card
        >
      </b-tab>
    </b-tabs>
    <div>
      <b-modal
        scrollable
        v-model="modalContract"
        id="modalContract"
        size="xl"
        hide-footer
      >
        <b-table
          bordered
          class="position-relative"
          primary-key="contract_id"
          responsive
          show-empty
          :items="contractsList"
          :fields="fieldsContract"
          hover
        >
          <!-- hover -->
          <template #cell(contract_id)="data">
            {{ data.item.contract_id }}
          </template>
          <template #cell(company)="data">
            <div style="width: max-content">
              <b-badge variant="success">
                {{ data.item.contract_nature
                }}{{ data.item.draft ? "(draft)" : "" }}</b-badge
              ><br />{{ data.item.company }}
            </div>
          </template>
          <template #cell(title)="data">
            <div style="width: max-content" v-html="data.value"></div>
          </template>
          <template #cell(territory)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(right)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(language)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(purchaseDate)="data">
            <b-badge>Contract Date : {{ data.item.purchaseDate }} </b-badge
            ><br />
            <b-badge variant="success" class="mt-1">
              Start : {{ data.item.rStart }} </b-badge
            ><br />

            <b-badge variant="danger" class="mt-1">
              End : {{ data.item.rExpiry }}</b-badge
            >
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                variant="primary"
                size="sm"
                @click="GetContractData(data.item)"
              >
                Select Contract
              </b-button>
            </div>
          </template>
          <template #cell()="data">
            <span class="text-nowrap">{{ data.value }}</span>
          </template>
        </b-table>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BBadge,
  VBToggle,
  BSpinner,
  BCardText,
  BTabs,
  BTab,
  BModal,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import { getAllMovie } from "@/apiServices/MovieServices";
import * as XLSX from "xlsx";

import { getAllData } from "@/apiServices/MastersServices";
import { AddExpenseData, GetMoviesVendor } from "@/apiServices/RevenueServies";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { downloadFromURL } from "@/utils/helpers";
import * as ContractServices from "@/apiServices/ContractServices";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    vSelect,
    flatPickr,
    XLSX,
    ToastificationContentVue,
    AppCollapseItem,
    AppCollapse,
    VBToggle,
    BSpinner,
    BTabs,
    BTab,
    BModal,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fieldsContract: [
        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },

        {
          key: "company",
          label: "Contact",
        },
        {
          key: "title",
          label: "Movie",
        },
        {
          key: "territory",
          label: "territory",
        },
        {
          key: "right",
          label: "rights",
        },
        {
          key: "language",
          label: "languages",
        },
        {
          key: "purchaseDate",
          label: "contract dates",
        },

        {
          key: "language",
          label: "languages",
        },
        { key: "contract_id", label: "Contract ID" },
      ],
      showSuccess: false,
      insertedDataCount: null,
      showErrorData: false,
      errorExcelData: null,
      BASE_URL: process.env.VUE_APP_BASEURL,
      expenseDate: null,
      expenseFile: null,
      selectedCustomer: null,
      loading: false,
      optionCustomer: [],
      allTeritory: [],
      selectedRight: null,
      expenseAmount: null,
      optionRights: [],
      contractsList: [],
      revenueTypeArray: [],
      optionMovies: [],
      selectedMovie: null,
      optionTeritory: [],
      selectedTeritory: null,
      modalContract: false,
      showExpenseSection: false,
      uploaded_documents: null,
      contractID: null,
      optionAvailableTeritory: [],
    };
  },

  computed: {},
  watch: {},
  beforeMount() {
    this.GetAllTypes();
    this.GetAllMovie();
  },
  methods: {
    async GetContractData(data) {
      this.GetAllTypes();
      this.contractID = data.contract_id;
      this.optionAvailableTeritory = [];

      let teriName = data.territory.split(",");
      let teriId = data.territory_ids.split(",").map(Number);

      for (let i = 0; i < teriName.length; i++) {
        let p = teriName[i];

        this.optionAvailableTeritory.push({
          ter: p,
          id: teriId[i],
        });
      }
      console.log(this.optionAvailableTeritory, "this.optionAvailableTeritory");
      this.modalContract = false;
      this.showExpenseSection = true;
    },
    async getReportContracts() {
      try {
        const response = await ContractServices.getAllContract({
          movie:
            this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          vendor: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : null,
          contract_nature: "Acquisition",
        });
        this.contractsList = response.data.data.contracts;

        if (!response.data.data.contracts.length) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Acquisition Contract Not Found!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          //if (this.contractsList.length > 1) {
          this.modalContract = true;
          // } else {
          //   // this.addExpenseData(this.contractsList[0].contract_id);
          // }
        }
      } catch (error) {
        console.log("Error in getting Contracts List", error);
      }
    },
    async GetAllTypes() {
      this.revenueTypeArray = [];
      this.optionRights = [];
      try {
        const response = await getAllData({
          master: "revenue_types",
          deleted: "0",
        });
        if (response.data) {
          this.optionRights = response.data.data.rows;

          let newAry = [
            { lbl_name: "BUMPER PAID", lbl_id: 2 },
            { lbl_name: "OVERAGES PAID", lbl_id: 3 },
            { lbl_name: "DIVERS PAID", lbl_id: 4 },
            { lbl_name: "P&A Expense", lbl_id: 5 },
            {
              lbl_name: "Less Licensor corridor Paid",
              lbl_id: 6,
            },
          ];
          this.optionRights.push(...newAry);

          for (let item of this.optionRights) {
            let defaultObj = {
              lbl_name: item.lbl_name,
              lbl_value1: item.lbl_value1 ? item.lbl_value1 : "",
              lbl_id: item.lbl_id,
              territory_id: item.territory_id,
              expense_amount: item.expense_amount ? item.expense_amount : null,
            };

            this.revenueTypeArray.push(defaultObj);
          }
        }
      } catch (err) {
        console.log("Error in getting territory list ", err);
      }
    },
    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async getMoviesVendorData() {
      this.optionCustomer = [];
      try {
        const response = await GetMoviesVendor({
          movie_id: this.selectedMovie.movie_id,
        });
        if (response.data.status) {
          this.optionCustomer = response.data.data;
          if (this.optionCustomer.length == 1) {
            this.selectedCustomer = this.optionCustomer[0];
          }
        }
      } catch (error) {
        console.log("Error in getting Contracts List");
      }
    },
    async getMovieVendors() {
      this.selectedCustomer = null;
      this.getMoviesVendorData();
    },
    async addExpenseData() {
      this.contract_id = this.contractID;
      this.loading = true;
      try {
        let payload = {
          vendor_id: this.selectedCustomer.vendor_id,
          movie_id: this.selectedMovie.movie_id,
          territory_id: 0,
          expense_date: this.expenseDate,
          expense_amount: null,
          expense_type: null,
          uploaded_documents: this.uploaded_documents,
          revenueTypeArray: this.revenueTypeArray,
          contract_id: this.contract_id,
        };
        const response = await AddExpenseData(payload);
        if (response.data.status) {
          console.log(response.data, "response.data");
          // this.GetAllTypes();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Added Successfully",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          this.resetForm();
        } else {
          console.log(response.data, "response.data1");
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.resetForm();
        }

        this.modalContract = false;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in getting movies list ", err);
      }
    },
    downloadSample() {
      fetch(this.BASE_URL + "/revenue/expensesampledownload", {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          downloadFromURL(_url, "SampleExpense", "xlsx");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async UploadExpenseData() {
      this.loading = true;
      try {
        const url = this.BASE_URL + "/revenue/uploadBulkExpense";
        const formData = new FormData();
        formData.append("file", this.expenseFile);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
            authorization: localStorage.getItem("access_token"),
          },
        };

        const response = await axios.post(url, formData, config);

        this.loading = false;
        if (response.data.status) this.expenseFile = null;
        if (!response.data.status) {
          if (response.data.data) {
            this.errorExcelData = response.data.data;
          }
          return this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Upload Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Uploaded successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.loading = false;
        console.log("Error in UploadExpense ", err);
      }
    },
    downloadErrorExcel() {
      if (this.errorExcelData) {
        let data = this.errorExcelData;
        let sheetName = "Expense";
        let excelName = "ErrorExpenseExcel.xlsx";
        var dataFinal = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataFinal, sheetName);
        XLSX.writeFile(wb, excelName);
      }
    },
    resetForm() {
      console.log("jitu");
      this.selectedCustomer = null;
      this.selectedMovie = null;
      this.selectedTeritory = null;
      this.selectedRight = null;
      this.expenseAmount = null;
      this.expenseDate = null;
      this.expenseFile = null;
      this.revenueTypeArray = [];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
